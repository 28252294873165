import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCoachMandate } from '@brainstud/academy-api/Hooks/useCoachMandates';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { useTranslator } from 'Providers/Translator';
import { sortOnPropFactory } from '../../Utils/sortOnProp';
import { TEnrollmentModalState } from './Types';

export type SelectCoachProps = {
  enrollmentId?: string;
  onStateChange: (state: TEnrollmentModalState) => void;
};

const SelectCoach = ({ enrollmentId, onStateChange }: SelectCoachProps) => {
  const [t] = useTranslator();
  const [loading, setLoading] = useState(false);
  const [{ data: enrollment }, { isFetching }] = useEnrollment({
    enrollment: enrollmentId,
    filter: {
      coach_activity: '-6 months',
    },
  });
  const coaches = useMemo(() => enrollment?.coaches?.(), [enrollment]);
  const { invalidateQueries } = useApi();
  const [{ create: createMandate }] = useCoachMandate(
    {
      enrollment: enrollmentId,
    },
    { enabled: false }
  );

  const currentCoach = coaches?.find((coach) => coach.isActiveCoach);
  const setCoach = useCallback(
    (coachId: string) => {
      setLoading(true);
      return createMandate
        .mutateAsync(
          {
            coach: coachId,
          },
          {
            onSettled: () =>
              Promise.all(invalidateQueries(['v1.enrollments'])).then(),
          }
        )
        .then(() => setLoading(false));
    },
    [invalidateQueries, createMandate]
  );

  useEffect(() => {
    onStateChange(loading ? 'loading' : 'done');
  }, [loading, onStateChange]);

  return (
    <div>
      <Dropdown
        block
        searchable
        label={t('modals.enrollment.steps[0].input')}
        placeholder={t('modals.enrollment.steps[0].placeholder')}
        disabled={loading || isFetching}
        onChange={(option) => option && setCoach(option)}
        defaultValue={currentCoach?.id}
      >
        {coaches?.sort(sortOnPropFactory('firstName')).map((coach) => (
          <Dropdown.Option key={coach.id} value={coach.id}>
            {`${coach.firstName || ''} ${coach.lastNameInsertion || ''} ${coach.lastName || ''}`}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default SelectCoach;
