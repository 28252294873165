import React from 'react';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';

export interface TeaserModalProps {
  teaserUrl: string;
  closeModal: () => void;
}

const TeaserModal = ({ teaserUrl, closeModal }: TeaserModalProps) => (
  <ContentModal
    onClickOutside
    onClose={closeModal}
    style={{ background: 'black' }}
  >
    <iframe
      src={teaserUrl}
      frameBorder="0"
      width="100%"
      height="320"
      allow="autoplay; fullscreen"
      allowFullScreen
      title="Course teaser"
    />
  </ContentModal>
);

export default TeaserModal;
