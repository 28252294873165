import React, { useCallback, useMemo, useState } from 'react';
import { useEventCalendarItemEnrollment } from '@brainstud/academy-api/Hooks/useEventCalendarItemEnrollment';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { useOrders } from '@brainstud/academy-api/Hooks/useOrders';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { useProductAddOns } from '@brainstud/academy-api/Hooks/useProductAddOns';
import { ProductAddOn } from '@brainstud/academy-api/Types/Resources/ProductAddOn';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { Callout } from '@brainstud/ui/Static/Callout';
import { ReportProblem } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import className from 'classnames/bind';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { usePortalParameter } from '../../Hooks/usePortalParameter';
import styles from './CourseProductModal.module.css';

const cx = className.bind(styles);

type Props = {
  courseId: string;
  closeModal: () => void;
};

const CourseProductModal = ({ courseId, closeModal }: Props) => {
  const [t] = useTranslator();
  const router = useRouter();

  // States
  const [selectedEvent, setEvent] = useState<string>();
  const [selectedAddOn, setAddOn] = useState<ProductAddOn>();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  // Memoized values
  const [{ data: courses }] = usePortalCoursesData();
  const course = useMemo(
    () => courses.find((item) => item.id === courseId),
    [courseId, courses]
  );
  const catalogProduct = useMemo(
    () =>
      course
        ?.catalogProducts?.()
        .find((product) => product.priceModel === 'flat_fee'),
    [course]
  );
  const eventCalendar = useMemo(
    () => selectedAddOn?.eventCalendar?.(),
    [selectedAddOn]
  );
  const events = useMemo(
    () => eventCalendar?.items?.().filter((item) => item.enrollable) || [],
    [eventCalendar]
  );
  const selectedIsSoldOut = !!eventCalendar && events.length === 0;

  // Data retrieval
  const [me] = useMe();
  const account = useMemo(() => me?.account?.(), [me]);
  const beforeVatPrice = !!usePortalParameter('show_prices_excluding_vat');
  const [{ data: productAddOns }, { isLoading }] = useProductAddOns({
    product: catalogProduct?.id,
  });
  const [{ create: createOrder }] = useOrders(undefined, { enabled: false });
  const [{ create: enroll }] = useEventCalendarItemEnrollment(
    {
      eventCalendarItem: selectedEvent,
    },
    { enabled: false }
  );

  // Handlers
  const handleOrder = useCallback(
    () =>
      createOrder.mutateAsync(
        {
          order_lines: [
            {
              catalog_product: catalogProduct?.id!,
              product_add_on: selectedAddOn?.id,
            },
          ],
          state: 'pending',
        },
        {
          onError: (error) => {
            /* eslint-disable-next-line no-console */
            console.error('API failed to create order.', error);
            setErrorMessage(t('error'));
          },
          onSuccess: async (response) => {
            const result = response.data;
            if (result.id) {
              if (selectedEvent && account) {
                await enroll.mutateAsync({
                  email_address: account.emailAddress,
                  state: 'pending',
                });
              }
              const uriSuffix = result.preRegistrationRequired
                ? 'user-details'
                : 'methods';
              router.push(
                `/account/orders/${result.id}/${uriSuffix}?returnTo=/`
              );
            } else {
              /* eslint-disable-next-line no-console */
              console.error('Unexpected API response data', response);
              setErrorMessage(t('error'));
            }
          },
        }
      ),
    [
      selectedAddOn,
      selectedEvent,
      catalogProduct,
      enroll,
      createOrder,
      account,
      t,
      router,
    ]
  );

  return !course || !catalogProduct ? (
    <Loading />
  ) : (
    <ContentModal onClose={closeModal} className={styles.base}>
      <ContentModal.Header>
        <h2>{`${t('modals.CourseProductModal.course')}: ${course.title}`}</h2>
      </ContentModal.Header>
      <div
        dangerouslySetInnerHTML={{ __html: sanitizer(course.description) }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        !!productAddOns?.length && (
          <>
            <hr />
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <h3>{t('modals.CourseProductModal.select_course_type')}</h3>
                <Grid container spacing={1}>
                  {productAddOns?.map((addOn) => {
                    const addOnEventCalendarItems =
                      addOn
                        .eventCalendar?.()
                        ?.items?.()
                        .filter((item) => item.enrollable) || [];
                    const isSoldOut = addOnEventCalendarItems.length === 0;
                    const beforeOrAfterVatAddOn = beforeVatPrice
                      ? 'priceBumpBeforeVat'
                      : 'priceBumpAfterVat';
                    const beforeOrAfterVatProduct = beforeVatPrice
                      ? 'priceBeforeVat'
                      : 'priceAfterVat';
                    const totalPrice =
                      parseFloat(
                        addOn[beforeOrAfterVatAddOn]?.toString() || '0'
                      ) +
                      parseFloat(
                        catalogProduct?.[beforeOrAfterVatProduct]?.toString() ||
                          '0'
                      );
                    const isSelected = addOn.id === selectedAddOn?.id;
                    return (
                      <Grid key={addOn.id} item md={4} sm={6} xs={12}>
                        <div
                          className={cx('addOnItem', {
                            selectedAddOn: addOn.id === selectedAddOn?.id,
                          })}
                        >
                          <h3>{addOn.name}</h3>
                          {isSoldOut && (
                            <span className={styles.warning}>
                              <ReportProblem />
                              {t('modals.CourseProductModal.sold_out')}
                            </span>
                          )}
                          <h2>
                            {totalPrice.toLocaleString('nl-nl', {
                              style: 'currency',
                              currency: catalogProduct?.currency,
                            })}
                          </h2>
                          <Button
                            outline={isSoldOut}
                            onClick={() => {
                              setAddOn(addOn);
                              setEvent(undefined);
                            }}
                          >
                            {isSoldOut
                              ? t('modals.CourseProductModal.more_info')
                              : isSelected
                                ? t('modals.CourseProductModal.selected')
                                : t('modals.CourseProductModal.select')}
                          </Button>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {events.length > 0 && (
                <Grid item xs={12} md={4}>
                  <h3>{t('modals.CourseProductModal.select_start_date')}</h3>
                  <Dropdown
                    block
                    placeholder={t(
                      'modals.CourseProductModal.start_date_placeholder'
                    )}
                    onChange={(value) => setEvent(value || undefined)}
                    value={selectedEvent}
                  >
                    {events?.map((eventItem) => (
                      <Dropdown.Option key={eventItem.id} value={eventItem.id}>
                        {eventItem.title}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                </Grid>
              )}
              {selectedIsSoldOut && (
                <Grid item xs={12} md={4}>
                  <h3>{t('modals.CourseProductModal.no_data.title')}</h3>
                  <p>{t('modals.CourseProductModal.no_data.text')}</p>
                </Grid>
              )}
            </Grid>
          </>
        )
      )}
      {errorMessage && (
        <Callout error margin="1rem 0">
          {errorMessage}
        </Callout>
      )}
      <ContentModal.Footer>
        <Button quiet onClick={closeModal}>
          {t('modals.CourseProductModal.cancel')}
        </Button>
        <Button
          disabled={
            selectedIsSoldOut ||
            (Boolean(productAddOns?.length) && !selectedAddOn) || // addOns present and one is picked
            (Boolean(events?.length) && !selectedEvent) // events present and one is picked
          }
          loading={createOrder.isPending || isLoading}
          onClick={handleOrder}
        >
          {t('modals.CourseProductModal.signup')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};

export default CourseProductModal;
