import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  ProductAddOn,
  ProductAddOnCreateInput,
  ProductAddOnFilters,
  ProductAddOnIncludes,
  ProductAddOnSorts,
  ProductAddOnUpdateInput,
} from '../Types/Resources/ProductAddOn';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IProductAddOnProps
  extends HookParams<
    typeof ProductAddOnIncludes,
    typeof ProductAddOnFilters,
    typeof ProductAddOnSorts
  > {
  portal?: UUID;
  product?: UUID;
  productAddOn?: UUID;
}

export function useProductAddOn(
  {
    productAddOn,
    portal,
    product,
    ...queryParameters
  }: IProductAddOnProps = {},
  settings?: IUseQueryOptions<DataDocument<ProductAddOn>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      portal: defaultPortal,
      product: defaultProduct,
      productAddOn: defaultProductAddOn,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(productAddOn || defaultProductAddOn) &&
        !!(portal || defaultPortal) &&
        !!(product || defaultProduct),
      baseName: 'v1.productAddOns',
      baseUri: `/v1/portals/${portal || defaultPortal}/products/${product || defaultProduct}/product_add_ons`,
      uri: `/${productAddOn || defaultProductAddOn}`,
      invalidate: ['product_add_ons', 'product_add_on', 'add_on', 'add_ons'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ProductAddOn,
    ProductAddOnCreateInput,
    ProductAddOnUpdateInput
  >(request, settings);
}

export function useProductAddOns(
  {
    product,
    portal,
    ...queryParameters
  }: Omit<IProductAddOnProps, 'productAddOn'> = {},
  settings?: IUseQueryOptions<DataDocument<ProductAddOn[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal, product: defaultProduct },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal) && !!(product || defaultProduct),
      baseName: 'v1.productAddOns',
      baseUri: `/v1/portals/${portal || defaultPortal}/products/${product || defaultProduct}/product_add_ons`,
      invalidate: ['product_add_ons', 'product_add_on', 'add_on', 'add_ons'],
      queryParameters,
    },
    context
  );
  return useIndexCR<ProductAddOn, ProductAddOnCreateInput>(request, settings);
}
