import React, { useCallback } from 'react';
import { useExposition } from '@brainstud/academy-api/Hooks/useExpositions';
import { Exposition } from '@brainstud/academy-api/Types/Resources/Exposition';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';

type Props = {
  closeModal: () => void;
  exposition: Exposition;
};

export const DeleteExpositionModal = ({ closeModal, exposition }: Props) => {
  const [t] = useTranslator();
  const [{ destroy }] = useExposition({ exposition: exposition?.id });
  const router = useRouter();

  const handleSubmit = useCallback(
    () =>
      destroy.mutateAsync(undefined, {
        onSuccess: () => {
          router.replace('/portfolio/expositions');
          closeModal();
        },
      }),
    [closeModal, destroy, router]
  );

  return (
    <ConfirmationModal
      question={t('delete')}
      title={t('delete')}
      description={t('modals.exposition.body.destroy')}
      onClose={closeModal}
      yes={t('modals.exposition.submit.destroy')}
      no={t('cancel')}
      isDanger
      handleConfirm={handleSubmit}
    />
  );
};
