import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import { LaunchLtiResourceModal } from 'Modals/Integrations/LTI/LaunchLtiResourceModal';
import { useRouter } from 'next/router';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { CourseProductModal, EnrollmentModal } from '../Modals';

type TSettings = {
  course: Course | undefined;
};

type ProductPriceModels =
  | 'flat_fee'
  | 'in_plan'
  | 'free'
  | 'subscription'
  | 'external';

type TOutput = {
  label:
    | string
    | {
        flatFee: string;
        subscription: string;
      };
  requires: {
    payment: boolean;
    subscription: boolean;
    enrollment: boolean;
  };
  pricing: {
    subscription: boolean;
    free: boolean;
    flatFee: boolean;
    external: boolean;
  };
  hasAccess: boolean;
  handleOpenCourse: (type?: ProductPriceModels) => void;
  isLoading: boolean;
};

export function useCourseStartup({ course }: TSettings): TOutput {
  const catalogProducts = useMemo(() => course?.catalogProducts?.(), [course]);
  const { openModal, closeModal } = useModals();
  const [enrollmentId, setEnrollmentId] = useState<string>();
  const [opening, setOpening] = useState<boolean>(false);
  const [{ data: enrollment }] = useEnrollment({ enrollment: enrollmentId });
  const courseId = course?.id;
  const [t] = useTranslator();
  const router = useRouter();

  const handleOpenCourse = useCallback(
    (type?: ProductPriceModels) => {
      if (course?.enrollment) {
        if (course.enrollment().isValid) {
          if (course.opener === 'default') {
            router.push(`/courses/${course.id}`);
          } else if (course.opener === 'app') {
            router.push(`apps/${course.id}`);
          } else if (course.opener === 'lti') {
            openModal(LaunchLtiResourceModal, {
              ltiResourceLink: course.ltiResourceLinks?.()[0],
              closeModal,
            });
          } else if (
            ['content_package', 'external_url'].includes(course.opener)
          ) {
            window.location.href = course.courseLink;
          }
        } else {
          setEnrollmentId(course.enrollment().id);
          setOpening(true);
        }
      } else if (type === 'flat_fee') {
        openModal(CourseProductModal, { courseId });
      } else {
        openModal(EnrollmentModal, { courseId });
      }
    },
    [course, router, openModal, closeModal, courseId]
  );

  useEffect(() => {
    if (enrollment && opening) {
      setOpening(false);
      if (course?.opener === 'external_url') {
        window.location.href = course.courseLink;
      } else if (!enrollment.coachMandates?.().length) {
        openModal(EnrollmentModal, { courseId, enrollment, step: 0 });
      } else if (!enrollment.learningObjectCollection?.()) {
        openModal(EnrollmentModal, { courseId, enrollment, step: 1 });
      } else if (!enrollment.educationLevel?.()) {
        openModal(EnrollmentModal, { courseId, enrollment, step: 2 });
      } else if (course) {
        if (course.opener === 'default') {
          router.push(`courses/${course.id}`);
        } else if (course.opener === 'app') {
          router.push(`apps/${course.id}`);
        } else if (course.opener === 'lti') {
          openModal(LaunchLtiResourceModal, {
            ltiResourceLink: course.ltiResourceLinks?.()[0],
            closeModal,
          });
        } else {
          window.location.href = course.courseLink;
        }
      } else {
        throw Error(
          'The course was not found. A report has been send. Sorry for the inconvenience.'
        );
      }
    }
  }, [enrollment, courseId, openModal, opening, course, router]);

  const pricing = {
    free: !!catalogProducts?.some((product) => product.priceModel === 'free'),
    flatFee: !!catalogProducts?.some(
      (product) => product.priceModel === 'flat_fee'
    ),
    subscription: !!catalogProducts?.some(
      (product) => product.priceModel === 'subscription'
    ),
    plan: !!catalogProducts?.some(
      (product) => product.priceModel === 'in_plan'
    ),
    external: !!catalogProducts?.some(
      (product) => product.priceModel === 'external'
    ),
  };

  const hasAccess = !!course?.hasAccess;
  const requires = {
    payment: (pricing.flatFee || pricing.external) && !hasAccess,
    subscription: (pricing.subscription || pricing.plan) && !hasAccess,
    enrollment: !course?.enrollment,
  };

  const label: TOutput['label'] = !hasAccess
    ? (requires.subscription &&
        requires.payment && {
          flatFee: t('components.course_card.actions.buy'),
          subscription: t('components.course_card.actions.buy_subscription'),
        }) ||
      (requires.subscription &&
        t('components.course_card.actions.buy_subscription')) ||
      (requires.payment && t('components.course_card.actions.buy')) ||
      t('components.course_card.actions.start')
    : (requires.enrollment && t('components.course_card.actions.enroll')) ||
      t('components.course_card.actions.start');

  return {
    isLoading: opening,
    label,
    requires,
    handleOpenCourse,
    hasAccess,
    pricing,
  };
}
