import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useLearningObjectCollection } from '@brainstud/academy-api/Hooks/useLearningObjectCollections';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { useTranslator } from 'Providers/Translator';
import { sortOnPropFactory } from '../../Utils/sortOnProp';
import { TEnrollmentModalState } from './Types';

export type SelectLevelProps = {
  enrollmentId?: string;
  onStateChange: (state: TEnrollmentModalState) => void;
};

export const SelectLevel = ({
  enrollmentId,
  onStateChange,
}: SelectLevelProps) => {
  const [t] = useTranslator();
  const [loading, setLoading] = useState(false);
  const [{ data: enrollment, update: updateEnrollment }, { isLoading }] =
    useEnrollment({
      enrollment: enrollmentId,
    });
  const currentLevel = enrollment?.educationLevel?.();
  const [{ data: collection }] = useLearningObjectCollection({
    learningObjectCollection: enrollment?.learningObjectCollection?.().id,
  });
  const educationLevels = useMemo(
    () => collection?.enrollableEducationLevels || [],
    [collection]
  );

  const setLevelId = useCallback(
    (levelId: string) => {
      setLoading(true);
      return updateEnrollment.mutate(
        { education_level: levelId },
        {
          onSettled: () => setLoading(false),
        }
      );
    },
    [updateEnrollment]
  );

  useEffect(() => {
    onStateChange(
      loading || isLoading ? 'loading' : currentLevel ? 'done' : 'blocked'
    );
  }, [loading, isLoading, currentLevel, onStateChange]);

  return (
    <div>
      <Dropdown
        block
        label={t('modals.enrollment.steps[2].input')}
        placeholder={t('modals.enrollment.steps[2].placeholder')}
        disabled={loading}
        rules="required"
        onChange={(option) => option && setLevelId(option)}
        defaultValue={`${currentLevel?.eqfNumber}`}
      >
        {educationLevels.sort(sortOnPropFactory('eqfNumber')).map((level) => (
          <Dropdown.Option key={level.eqfNumber} value={`${level.eqfNumber}`}>
            {level.label}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};
