import React, { useCallback, useMemo, useState } from 'react';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { Callout } from '@brainstud/ui/Static/Callout';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { Loading } from 'Components/Loading';
import { useBreakpoints } from 'Hooks/useBreakpoints';
import { useOnMount } from 'Hooks/useOnMount';
import { useCourseStartup } from 'Modules/blended-learning-catalog-panel/Hooks';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as CoachSVG } from '../../Assets/coach-icon.svg';
import { ReactComponent as LeersetSVG } from '../../Assets/leerset-icon.svg';
import { ReactComponent as NiveauSVG } from '../../Assets/niveau-icon.svg';
import { ProgressStepper } from '../../Components/ProgressStepper';
import SelectAssignmentSet from './SelectAssignmentSet';
import SelectCoach from './SelectCoach';
import { SelectLevel } from './SelectLevel';
import { EnrollmentModalProps, TEnrollmentModalState } from './Types';
import styles from './EnrollmentModal.module.css';

const EnrollmentModal = ({
  courseId,
  enrollment,
  step: startStep = 0,
  closeModal,
}: EnrollmentModalProps) => {
  const [t] = useTranslator();
  const [step, setStep] = useState(startStep);

  const [enrollmentId, setEnrollmentId] = useState<string>();
  const [me] = useMe();
  const [{ createOrUpdate: createEnrollment }] = useEnrollment(
    {
      enrollment: enrollment?.id,
    },
    { enabled: false }
  );

  const [{ data: courses }] = usePortalCoursesData();
  const course = useMemo(
    () => courses.find((item) => item.id === courseId),
    [courseId, courses]
  );
  const [state, setState] = useState<TEnrollmentModalState>(null);
  const icons = [<CoachSVG />, <LeersetSVG />, <NiveauSVG />];
  const isScormCourse = course?.opener === 'scorm';

  const { handleOpenCourse } = useCourseStartup({ course });

  useOnMount(() => {
    // Check if enrollment is passed or the id is saved in state
    if (!enrollment && !enrollmentId) {
      // if enrollment is not being created, create enrollment
      if (!createEnrollment.isPending) {
        createEnrollment
          .mutateAsync({
            course: courseId,
            portal: me?.portal?.().id as string,
          })
          .then(({ data }) => {
            setEnrollmentId(data.id);
            if (course?.opener === 'external' && course?.courseLink) {
              window.location.href = course.courseLink;
            }
          });
      }
      // if enrollment is passed but not saved in state, save it in state
    } else if (!enrollmentId) {
      setEnrollmentId(enrollment?.id);
    }
  }, [
    courseId,
    createEnrollment,
    me,
    enrollment,
    createEnrollment.isPending,
    enrollmentId,
    course?.courseLink,
    course?.opener,
  ]);

  const handleStateChange = useCallback((prevState) => setState(prevState), []);

  const handlePrevious = useCallback(() => {
    if (step > 0) setStep((prevStep) => prevStep - 1);
  }, [step]);

  const router = useRouter();
  const handleNext = useCallback(() => {
    if (step < 2 && !isScormCourse) {
      setStep((prevStep) => prevStep + 1);
    } else if (course) {
      handleOpenCourse();
    } else {
      throw new Error(`Unknown course id ${courseId}. A report has been send.`);
    }
  }, [step, isScormCourse, course, router, courseId]);

  const { mdUp } = useBreakpoints();

  return (
    <ContentModal
      onClose={closeModal}
      className={styles.modal}
      style={{ overflow: 'visible' }}
    >
      <ContentModal.Header>
        <h1>{t('modals.enrollment.title')}</h1>
        <ProgressStepper step={step + 1} length={isScormCourse ? 1 : 3} />
      </ContentModal.Header>
      {createEnrollment.isPending ? (
        <Loading />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <h4>{t(`modals.enrollment.steps[${step}].title`)}</h4>
              <p>{t(`modals.enrollment.steps[${step}].description`)}</p>
            </Grid>
            {mdUp && (
              <Grid item xs={12} md={6}>
                <figure className={styles.icon}>{icons[step]}</figure>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {step === 1 && (
                <Callout warning>
                  <h4>{t('modals.enrollment.alert_title')}</h4>
                  <p>{t(`modals.enrollment.steps[${step}].alert`)}</p>
                </Callout>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {step === 0 && (
                <SelectCoach
                  enrollmentId={enrollmentId}
                  onStateChange={handleStateChange}
                />
              )}
              {step === 1 && (
                <SelectAssignmentSet
                  enrollmentId={enrollmentId}
                  onStateChange={handleStateChange}
                />
              )}
              {step === 2 && (
                <SelectLevel
                  enrollmentId={enrollmentId}
                  onStateChange={handleStateChange}
                />
              )}
            </Grid>
          </Grid>
          <ContentModal.Footer>
            <Button
              onClick={handleNext}
              loading={state === 'loading'}
              disabled={state === 'blocked'}
            >
              <span>
                {step < 2 || isScormCourse
                  ? t('modals.enrollment.next')
                  : t('modals.enrollment.start')}
              </span>
              <ArrowForward />
            </Button>
            {step !== 0 && (
              <Button
                className={styles.previousButton}
                quiet
                disabled={step === 0}
                onClick={handlePrevious}
              >
                <ArrowBack />
                <span>{t('modals.enrollment.previous')}</span>
              </Button>
            )}
          </ContentModal.Footer>
        </>
      )}
    </ContentModal>
  );
};

export default EnrollmentModal;
