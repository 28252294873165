import React, { useCallback, useState } from 'react';
import { useExposition } from '@brainstud/academy-api/Hooks/useExpositions';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { Exposition } from '@brainstud/academy-api/Types/Resources/Exposition';
import { Button } from '@brainstud/ui/Buttons/Button';
import { FileCard } from '@brainstud/ui/FileHandling/FileCard';
import { UploadBox } from '@brainstud/ui/FileHandling/UploadBox';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Textarea } from '@brainstud/ui/Form Input/Textarea';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import { Callout } from '@brainstud/ui/Static/Callout';
import { Status } from '@brainstud/ui/Static/Status';
import { Form } from '@brainstud/universal-components/Components/Form';
import { FEATURES } from 'Config/features';
import { usePortalFeatureCheck } from 'Hooks/PortalFeatureCheck/usePortalFeatureCheck';
import { usePlausibleTracking } from 'Hooks/usePlausible';
import { useRouter } from 'next/router';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import styles from './ExpositionModalForm.module.css';

type Props = {
  exposition?: Exposition;
  learningObjectIds?: string[];
};
type FormData = {
  title: string;
  description: string;
};

export const ExpositionModalForm = ({
  exposition,
  learningObjectIds,
}: Props) => {
  const [t] = useTranslator();
  const { closeModal } = useModals();
  const { baseUrl, headers: apiHeaders } = useApi();
  const [imageId, setImageId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const plausible = usePlausibleTracking();

  const isFileUploadEnabled = usePortalFeatureCheck(FEATURES.ACCOUNT_FILES);
  const [{ data: expositionData, createOrUpdate }] = useExposition({
    exposition: exposition?.id,
    include: ['learning_objects', 'stored_files'],
    showStoredFilesCount: isFileUploadEnabled,
  });

  const history = useRouter();
  const isEditing = !!exposition;

  const handleSubmit = useCallback(
    (formData: FormData) => {
      setLoading(true);

      // #TODO: fix this requirement check to be handled by de
      if (!formData.description) {
        setErrorMessage(t('modals.exposition.input.description.required'));
        setLoading(false);
        return undefined;
      }

      return createOrUpdate.mutateAsync(
        {
          ...formData,
          relationships: {
            learning_objects:
              learningObjectIds ||
              expositionData
                ?.learningObjects?.()
                .map((learningObject) => learningObject.id) ||
              [],
            ...(imageId && { image: imageId }),
          },
        },
        {
          onSuccess: (response) => {
            if (!isEditing) {
              history.push(`/portfolio/expositions/${response?.data?.id}`);
            }
            plausible('expositions:form:create', {
              existing: isEditing,
            });
            closeModal();
          },
          onError: (error) => {
            setErrorMessage(
              error?.errors?.map((item) => item.title).join('<br />') ||
                t('error')
            );
            setLoading(false);
          },
        }
      );
    },
    [
      closeModal,
      createOrUpdate,
      expositionData,
      history,
      imageId,
      isEditing,
      learningObjectIds,
      plausible,
      t,
    ]
  );

  const count =
    learningObjectIds?.length || exposition?.meta?.learningObjectCount;
  const fileCount = expositionData?.meta?.storedFilesCount || 0;

  return (
    <SidebarModal
      size="medium"
      onClickOutside
      onClose={closeModal}
      className={styles.base}
    >
      <Form<FormData> onSubmit={handleSubmit}>
        <SidebarModal.Header className={styles.horizontal}>
          <h2>
            {t(`modals.exposition.title.${!exposition ? 'create' : 'update'}`)}
          </h2>
          <Button type="submit" loading={loading}>
            {t('save')}
          </Button>
        </SidebarModal.Header>

        <div className={styles.vertical}>
          <div>
            <Status scheme="grey">
              {t(
                `modals.exposition.assignments_count${count !== 1 ? '_plural' : ''}`,
                { count: count || 0 }
              )}
            </Status>

            {isFileUploadEnabled && (
              <Status scheme="grey">
                {t(
                  `modals.exposition.files_count${fileCount !== 1 ? '_plural' : ''}`,
                  { count: fileCount }
                )}
              </Status>
            )}
          </div>
          <Input
            id="title"
            name="title"
            rules="required"
            label={t('modals.exposition.input.title.label')}
            placeholder={t('modals.exposition.input.title.placeholder')}
            defaultValue={exposition?.title}
          />
          <Textarea
            id="description"
            name="description"
            label={t('modals.exposition.input.description.label')}
            placeholder={t('modals.exposition.input.description.placeholder')}
            defaultValue={exposition?.description}
          />
          <div>
            <label htmlFor="thumbnail" className={styles['upload-label']}>
              {t('modals.exposition.input.thumbnail')}
            </label>
            {exposition?.image && !imageId && (
              <FileCard thumbnail={exposition.image}>
                {exposition?.title}
              </FileCard>
            )}
            <UploadBox
              url={`${baseUrl}/v1/services/temporary_file_upload`}
              headers={{ ...apiHeaders, 'Content-Type': undefined }}
              paramName="file"
              maxFiles={1}
              onAfterFileUpload={(file, response) => {
                if (response?.data?.id) {
                  setImageId(response?.data?.id);
                }
              }}
            />
          </div>
        </div>
        {errorMessage && (
          <Callout error margin="1rem 0">
            {errorMessage}
          </Callout>
        )}
      </Form>
    </SidebarModal>
  );
};
