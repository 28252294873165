import { useMemo } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';

/**
 * Finds a parameter set on the portal using the useMe Academy API hook.
 * @param parameter The parameter to look for.
 */
export function usePortalParameter(parameter: string) {
  const [me] = useMe();
  const portal = useMemo(() => me?.portal?.(), [me]);
  return portal?.parameters?.find((param) => param.type === parameter);
}
