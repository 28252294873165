import React, { useCallback } from 'react';
import { useAccountPreferences } from '@brainstud/academy-api/Hooks/useAccountPreferences';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { Delete } from '@mui/icons-material';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';

type TProps = {
  content: string;
  version: number;
  closeModal: () => void;
  closeMessage: () => void;
};

const WelcomeModal = ({
  content,
  version,
  closeMessage,
  closeModal,
}: TProps) => {
  const [{ create: createOrUpdate }] = useAccountPreferences({
    property: 'hide_message_version',
  });
  const [me] = useMe();
  const [t] = useTranslator();

  const handleClose = useCallback(() => {
    createOrUpdate.mutate({
      payload: `${version}`,
    });
    closeMessage();
    closeModal();
  }, [createOrUpdate, version, closeModal, closeMessage]);

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            content.replace('{{name}}', me?.account?.()?.firstName || 'student')
          ),
        }}
      />
      <Button outline onClick={handleClose}>
        <Delete fontSize="large" />
        <span>{t('modals.welcome_modal.hide_message')}</span>
      </Button>
    </ContentModal>
  );
};

export default WelcomeModal;
